import DataService from "./DataService";

const ArtistService = {
  getArtistDetails: async function (routeId: string) {
    const url = `website-builder/${routeId}`;
    return new Promise<any>((res, reject) => {
      DataService.get(url)
        .then((resp: any) => {
          console.log(resp);
          res(resp.data);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },
  addSubscriber: async function (data: object) {
    const url = `mailing-list/add-subscriber`;
    return new Promise<any>((res, reject) => {
      DataService.put(url, data)
        .then((resp: any) => {
          console.log(resp);
          res(resp.data);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },
  getFeaturedArtists: async function () {
    const url = `website-builder/fallback`;
    return new Promise<any>((res, reject) => {
      DataService.get(url)
        .then((resp: any) => {
          console.log(resp);
          res(resp.data);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },
};

export default ArtistService;
