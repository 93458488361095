import React from "react";

export default function CookiePopup({ choiceOutput }: { choiceOutput?: any }) {
  return (
    <>
      <div className="cookie-popup">
        <div className="cookie-popup-content">
          <h2 className="text-white">Cookie Policy</h2>
          <p className="text-white">
            This website uses cookies to ensure you get the best experience on
            our website. View our{" "}
            <a href="https://www.unhurd.co.uk/privacy-policy" target="blank">
              privacy policy
            </a>{" "}
            for more information.
          </p>
          <div className="cookie-popup-buttons">
            <button
              className="cookie-popup-button"
              onClick={() => choiceOutput(false)}
            >
              Decline
            </button>
            <button
              className="cookie-popup-button"
              onClick={() => choiceOutput(true)}
            >
              Accept
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
