import { Tooltip } from "@mui/material";
import React from "react";

interface Links {
  musicLinks: {
    amazonMusic: string | null;
    appleMusic: string | null;
    audimack: string | null;
    boomplay: string | null;
    deezer: string | null;
    spotify: string | null;
    youTube: string | null;
  };
  socialLinks: {
    facebook: string | null;
    instagram: string | null;
    snapchat: string | null;
    tikTok: string | null;
    twitter: string | null;
  };
}

export default function SocialsBar({
  links,
  theme,
}: {
  links: Links | any;
  theme: string;
}) {
  const logos = [
    // Music links
    {
      name: "apple-music",
      link: links?.musicLinks?.appleMusic ?? links?.appleMusic,
    },
    { name: "deezer", link: links?.musicLinks?.deezer ?? links?.deezer },
    { name: "spotify", link: links?.musicLinks?.spotify ?? links?.spotify },
    { name: "youtube", link: links?.musicLinks?.youTube ?? links?.youTube },
    {
      name: "audiomack",
      link: links?.musicLinks?.audiomack ?? links?.audiomack,
    },
    {
      name: "amazon-music",
      link: links?.musicLinks?.amazonMusic ?? links?.amazonMusic,
    },
    { name: "boomplay", link: links?.musicLinks?.boomplay ?? links?.boomplay },
    // Social links
    { name: "facebook", link: links?.socialLinks?.facebook ?? links?.facebook },
    {
      name: "instagram",
      link: links?.socialLinks?.instagram ?? links?.instagram,
    },
    { name: "tiktok", link: links?.socialLinks?.tikTok ?? links?.tikTok },
    { name: "twitter", link: links?.socialLinks?.twitter ?? links?.twitter },
    { name: "snapchat", link: links?.socialLinks?.snapchat ?? links?.snapchat },
  ];

  const openLink = (link: string) => {
    if (link.includes("http")) {
      window.open(link);
    } else {
      window.open(`https://${link}`);
    }
  };

  return (
    <div className="text-faded socials-card">
      {logos.map((item: any, index: number) => {
        if (!item.link) {
          return null;
        } else {
          return (
            <div
              key={index}
              className="socials-container cursor-pointer"
              onClick={() => {
                openLink(item.link);
              }}
            >
              <Tooltip title={item.name.replace("-", " ")} placement="top">
                <img
                  className={
                    theme === "light" &&
                    (item.name === "twitter" ||
                      item.name === "apple-music" ||
                      item.name === "snapchat" ||
                      item.name === "audiomack" ||
                      item.name === "boomplay" ||
                      item.name === "tiktok")
                      ? "invert"
                      : theme === "dark" && item.name === "apple"
                      ? "invert"
                      : ""
                  }
                  src={`/images/logos/${item.name}-logo.svg`}
                  alt=""
                />
              </Tooltip>
            </div>
          );
        }
      })}
    </div>
  );
}
