import React, { useContext, useEffect, useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";

import { Navigation, Pagination, Scrollbar, A11y } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { Button, CircularProgress, Icon } from "@mui/material";
import ArtistService from "../services/ArtistService";
import SocialsBar from "./SocialsBar";
import SnackbarContext from "../contexts/SnackBarContext";

export default function Carousel({
  artistDetails,
  theme,
}: {
  artistDetails: any;
  theme: string;
}) {
  const [phoneNumber, setPhoneNumber] = useState<number>();
  const [email, setEmail] = useState<string>("");
  const [slideIndex, setSlideIndex] = useState<number>(0);
  const { dispatchSnackbar } = useContext<any>(SnackbarContext);
  const [isSubmittingSubscriber, setIsSubmittingSubscriber] =
    useState<boolean>();

  const [breakPointHit, setBreakPointHit] = useState<boolean>(false);

  const handleResize = () => {
    window.visualViewport && window.visualViewport.width <= 900
      ? setBreakPointHit(true)
      : setBreakPointHit(false);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    handleResize();
  }, [window.innerWidth]);

  const slider: any = useRef();

  useEffect(() => {
    console.log(slider.current.swiper.activeIndex);
  }, [slideIndex]);

  const isEmailValid = (email: any) => {
    return new RegExp("[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}").test(email);
    // return new RegExp('/^[^\s@]+@[^\s@]+\.[^\s@]+$/').test(email);
  };

  const addSubscriber = () => {
    setIsSubmittingSubscriber(true);
    const data = {
      mailingListId: artistDetails.mailingList.mailingListId,
      artistId: artistDetails.artistId,
      email: email,
      phoneNumber: phoneNumber,
    };
    ArtistService.addSubscriber(data)
      .then((resp: any) => {
        console.log(resp);
        setEmail("");
        setPhoneNumber(undefined);
        dispatchSnackbar({
          type: "OPEN_SNACKBAR",
          payload: {
            message: `You have been added to ${artistDetails.artistName}'s mailing list`,
            type: "success",
          },
        });
        setIsSubmittingSubscriber(false);
      })
      .catch((err: any) => {
        console.log(err);
        setEmail("");
        setPhoneNumber(undefined);
        dispatchSnackbar({
          type: "OPEN_SNACKBAR",
          payload: {
            message: `${err.response.data.errorMessage}`,
            type: "error",
          },
        });
        setIsSubmittingSubscriber(false);
      });
  };

  const openLink = (link: string) => {
    if (link.includes("http")) {
      window.open(link);
    } else {
      window.open(`https://${link}`);
    }
  };

  return (
    <div>
      <Swiper
        ref={slider}
        spaceBetween={breakPointHit ? 16 : 100}
        modules={[Navigation, Pagination, Scrollbar, A11y]}
        slidesPerView={1.3}
        navigation={true}
        centeredSlides={true}
        pagination={{ clickable: true }}
      >
        <SwiperSlide>
          <div className="carousel-card">
            <img
              className="profile-pic mt20"
              src={artistDetails?.profileImageUrl}
              alt=""
            />
            <p className="mt20">{artistDetails?.bio}</p>
            <div className="d-flex flex-wrap jc-center gap20 mt40">
              {artistDetails?.primaryLinks.map((item: any, index: number) => (
                <Button
                  key={index}
                  onClick={() => {
                    openLink(item.link);
                  }}
                >
                  {item.customText}
                </Button>
              ))}
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="carousel-card">
            <h3 className="mt20">
              Join {artistDetails?.artistName}'s community
            </h3>
            <p className="mt20">{artistDetails?.community?.customText}</p>
            <div className="w60p ml-auto mr-auto mt20">
              <div className="d-flex gap20 flex-wrap jc-center mt40 mb20">
                {artistDetails?.community.discordChannel && (
                  <Button
                    onClick={() => {
                      openLink(artistDetails?.community?.discordChannel);
                    }}
                  >
                    <img src="/images/logotypes/discord-logotype.svg" alt="" />
                  </Button>
                )}
                {artistDetails?.community?.whatsAppGroup && (
                  <Button
                    onClick={() => {
                      openLink(artistDetails?.community?.whatsAppGroup);
                    }}
                  >
                    <img src="/images/logotypes/whatsapp-logo.svg" alt="" />
                    <span className="ml10">WhatsApp</span>
                  </Button>
                )}
                {artistDetails?.community?.instagramBroadcast && (
                  <Button
                    onClick={() => {
                      openLink(artistDetails?.community?.instagramBroadcast);
                    }}
                  >
                    <img
                      src="/images/logotypes/instagram-logomark.svg"
                      alt=""
                    />
                    <span className="ml10">Broadcast</span>
                  </Button>
                )}
              </div>

              {artistDetails?.mailingList?.collectPhoneNumbers && (
                <input
                  className="mt20"
                  placeholder="Your phone number"
                  value={phoneNumber || ""}
                  type="number"
                  onChange={(e: any) => {
                    setPhoneNumber(e.target.value);
                  }}
                />
              )}
              {artistDetails?.mailingList?.collectEmails && (
                <div>
                  <input
                    className="mt20"
                    placeholder="Your email"
                    value={email || ""}
                    type="email"
                    onChange={(e: any) => {
                      setEmail(e.target.value);
                    }}
                  />
                  {email !== "" && !isEmailValid(email) && (
                    <p className="text-error text-left pl8">
                      Enter a valid email address
                    </p>
                  )}
                </div>
              )}
            </div>
            <Button
              className="mt20"
              disabled={
                isSubmittingSubscriber ||
                (!email && !phoneNumber) ||
                (email !== "" && !isEmailValid(email))
              }
              onClick={() => {
                addSubscriber();
              }}
            >
              {isSubmittingSubscriber ? (
                <CircularProgress size={16} />
              ) : (
                "Submit"
              )}
            </Button>
          </div>
        </SwiperSlide>

        {artistDetails?.spotlightRelease && (
          <SwiperSlide>
            <div className="carousel-card">
              <h3 className="mt20">
                Stream {artistDetails?.artistName}'s music
              </h3>
              <p className="mt20">
                {artistDetails?.spotlightRelease?.customText}
              </p>
              <div className="d-flex gap20 flex-wrap jc-center mt62 mb20 max-w650 ml-auto mr-auto">
                {artistDetails?.spotlightRelease?.spotifyLink && (
                  <Button
                    onClick={() => {
                      openLink(artistDetails?.spotlightRelease?.spotifyLink);
                    }}
                  >
                    <img src="/images/logotypes/spotify-logotype.svg" alt="" />
                  </Button>
                )}
                {artistDetails?.spotlightRelease?.appleMusicLink && (
                  <Button
                    onClick={() => {
                      openLink(artistDetails?.spotlightRelease?.appleMusicLink);
                    }}
                  >
                    <img
                      src="/images/logotypes/apple-music-logotype.svg"
                      alt=""
                    />
                  </Button>
                )}
                {artistDetails?.spotlightRelease?.iTunesLink && (
                  <Button
                    onClick={() => {
                      openLink(artistDetails?.spotlightRelease?.iTunesLink);
                    }}
                  >
                    <img src="/images/logotypes/itunes-logotype.svg" alt="" />
                  </Button>
                )}
                {artistDetails?.spotlightRelease?.youTubeMusicLink && (
                  <Button
                    onClick={() => {
                      openLink(
                        artistDetails?.spotlightRelease?.youTubeMusicLink
                      );
                    }}
                  >
                    <img
                      src="/images/logotypes/youtube-music-logotype.svg"
                      alt=""
                    />
                  </Button>
                )}
                {artistDetails?.spotlightRelease?.amazonMusicLink && (
                  <Button
                    onClick={() => {
                      openLink(
                        artistDetails?.spotlightRelease?.amazonMusicLink
                      );
                    }}
                  >
                    <img
                      src="/images/logotypes/amazon-music-logotype.svg"
                      alt=""
                    />
                  </Button>
                )}
                {artistDetails?.spotlightRelease?.deezerLink && (
                  <Button
                    onClick={() => {
                      openLink(artistDetails?.spotlightRelease?.deezerLink);
                    }}
                  >
                    <img src="/images/logotypes/deezer-logotype.svg" alt="" />
                  </Button>
                )}
              </div>
            </div>
          </SwiperSlide>
        )}
        {(artistDetails?.video?.url || artistDetails?.video?.embed) && (
          <SwiperSlide>
            <div className="carousel-card">
              <iframe
                id="youtube-video"
                className="youtube-video-vertical-center"
                width={"100%"}
                height={315}
                src={
                  artistDetails?.video?.embed ??
                  artistDetails?.video?.url.replace("watch?v=", "embed/")
                }
                title={"YouTube video player"}
                allowFullScreen
                frameBorder={0}
                allow={
                  "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                }
              ></iframe>
            </div>
          </SwiperSlide>
        )}

        {breakPointHit && artistDetails?.platformLinks && (
          <SwiperSlide>
            <div className="carousel-card">
              <h3 className="mb48 mt20">
                Find {artistDetails?.artistName} on other platforms
              </h3>

              <SocialsBar links={artistDetails?.platformLinks} theme={theme} />
            </div>
          </SwiperSlide>
        )}
      </Swiper>
      <div className="swiper-button-next">
        <Button
          className="icon-button"
          disabled={slideIndex === slider?.current?.swiper?.slides?.length - 1}
          onClick={() => {
            slider.current.swiper.slideNext();
            setSlideIndex(slider.current.swiper.activeIndex);
          }}
        >
          <Icon>chevron_right</Icon>
        </Button>
      </div>
      <div className="swiper-button-prev">
        <Button
          className="icon-button"
          disabled={slideIndex === 0}
          onClick={() => {
            slider.current.swiper.slidePrev();
            setSlideIndex(slider.current.swiper.activeIndex);
          }}
        >
          <Icon>chevron_left</Icon>
        </Button>
      </div>
    </div>
  );
}
