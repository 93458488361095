import axios from "axios";

const accessTokenState = async () => {
  const token = await localStorage.getItem("auth_token");
  return token;
};

const baseUrl = () => {
  if (
    process.env.REACT_APP_NODE_ENV === "dev" ||
    process.env.REACT_APP_NODE_ENV === "test"
  ) {
    return "https://api.unhurd.co.uk/website-builder/test/";
  } else {
    return "https://api.unhurd.co.uk/website-builder/v1/";
  }
};

const DataService = {
  get: async function (url: string) {
    console.log(url);
    return axios({
      url: baseUrl() + url,
      method: "GET",
      headers: {
        Accept: "application/json",
        "Ocp-Apim-Subscription-key": process.env.REACT_APP_API_KEY,
      },
    });
  },
  post: async function (url: string, data: object) {
    console.log(url, data);
    console.log(url);
    return axios({
      url: baseUrl() + url,
      method: "POST",
      data: JSON.stringify(data),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Ocp-Apim-Subscription-key": process.env.REACT_APP_API_KEY,
      },
    });
  },
  put: async function (url: string, data: object) {
    console.log(url, data);
    console.log(url);
    return axios({
      url: baseUrl() + url,
      method: "PUT",
      data: JSON.stringify(data),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Ocp-Apim-Subscription-key": process.env.REACT_APP_API_KEY,
      },
    });
  },

  delete: async function (url: string, data: object) {
    console.log(url);
    return axios({
      url: baseUrl() + url,
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Ocp-Apim-Subscription-key": process.env.REACT_APP_API_KEY,
      },
    });
  },
};

export default DataService;
