import React from "react";

export default function TitleCard({ name }: { name: string }) {
  return (
    <div className="text-faded title-card">
      <h1>{name}</h1>
      <p
        className="cursor-pointer mt10"
        onClick={() => {
          window.open("https://www.unhurd.co.uk");
        }}
      >
        Powered by{" "}
        <span>
          <img
            height={12}
            src="/images/logos/full-text-logo.svg"
            alt="unhurd-music-logo"
          />
        </span>
      </p>
    </div>
  );
}
