import React, { CSSProperties, useEffect, useState } from "react";
import TitleCard from "../components/TitleCard";
import Carousel from "../components/Carousel";
import SocialsBar from "../components/SocialsBar";
import { useNavigate, useParams } from "react-router-dom";
import ArtistService from "../services/ArtistService";
import { Button } from "@mui/material";
import Loading from "../components/Loading";
import CookiePopup from "../components/CookiePopup";
import SEO from "../components/SEO";

export default function ArtistPage() {
  const { slug } = useParams<string>();

  const navigate = useNavigate();
  const [theme, setTheme] = useState<string>("dark");
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [showCookiePolicy, setShowCookiePolicy] = useState<boolean>(true);
  const [cookiePolicyAccepted, setCookiePolicyAccepted] = useState<boolean>();

  const [artistDetails, setArtistDetails] = useState<any>();
  const [artistNotFound, setArtistNotFound] = useState<boolean>();

  const [breakPointHit, setBreakPointHit] = useState<boolean>(false);

  const handleResize = () => {
    window.visualViewport && window.visualViewport.width <= 900
      ? setBreakPointHit(true)
      : setBreakPointHit(false);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    handleResize();
  }, [window.innerWidth]);

  useEffect(() => {
    if (cookiePolicyAccepted) {
      if (artistDetails?.trackingPixels?.metaPixelId) {
        insertMetaPixels();
      }
      if (artistDetails?.trackingPixels?.tikTokPixelId) {
        insertTikTokPixel();
      }
    }
  }, [cookiePolicyAccepted]);

  const formMetaPixel = (metaPixelId: string) => {
    return `!function(f,b,e,v,n,t,s)
              {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
              n.callMethod.apply(n,arguments):n.queue.push(arguments)};
              if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
              n.queue=[];t=b.createElement(e);t.async=!0;
              t.src=v;s=b.getElementsByTagName(e)[0];
              s.parentNode.insertBefore(t,s)}(window, document,'script',
              'https://connect.facebook.net/en_US/fbevents.js');
              fbq('init', '${metaPixelId}');
              fbq('track', 'PageView')`;
  };

  const formTikTokPixel = (tikTokPixelId: string) => {
    return `!function (w, d, t) {
      w.TiktokAnalyticsObject=t;var ttq=w[t]=w[t]||[];
      ttq.methods=["page","track","identify","instances","debug","on","off","once","ready","alias","group","enableCookie","disableCookie"],ttq.setAndDefer=function(t,e){t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}};
      for(var i=0;i<ttq.methods.length;i++)ttq.setAndDefer(ttq,ttq.methods[i]);
      ttq.instance=function(t){for(var e=ttq._i[t]||[],n=0;n<ttq.methods.length;n++)ttq.setAndDefer(e,ttq.methods[n]);
      return e},ttq.load=function(e,n){var i="https://analytics.tiktok.com/i18n/pixel/events.js";
      ttq._i=ttq._i||{},ttq._i[e]=[],ttq._i[e]._u=i,ttq._t=ttq._t||{},ttq._t[e]=+new Date,ttq._o=ttq._o||{},ttq._o[e]=n||{};
      var o=document.createElement("script");
      o.type="text/javascript",o.async=!0,o.src=i+"?sdkid="+e+"&lib="+t;
      var a=document.getElementsByTagName("script")[0];
      a.parentNode.insertBefore(o,a)};
    }(window, document, 'ttq');
    
    ttq.load('${tikTokPixelId}');
    ttq.page();`;
  };

  const insertMetaPixels = () => {
    const metaScript = document.createElement("script");
    metaScript.innerHTML = formMetaPixel(
      artistDetails.trackingPixels.metaPixelId
    );
    document.getElementsByTagName("head")[0].appendChild(metaScript);
  };

  const insertTikTokPixel = () => {
    const tikTokScript = document.createElement("script");
    tikTokScript.innerHTML = formTikTokPixel(
      artistDetails.trackingPixels.tikTokPixelId
    );
    document.getElementsByTagName("head")[0].appendChild(tikTokScript);
  };

  useEffect(() => {
    if (slug) {
      console.log(slug);
      ArtistService.getArtistDetails(slug)
        .then((resp: any) => {
          setArtistDetails(resp);
          if (resp.theme.background.name.includes("dark")) {
            setTheme("dark");
          } else {
            setTheme("light");
          }
          if (
            resp.trackingPixels?.metaPixelId ||
            resp.trackingPixels?.tikTokPixelId
          ) {
            setShowCookiePolicy(true);
          } else {
            setShowCookiePolicy(false);
          }
          document.title = resp?.artistName || "un:hurd music artists";
          let link: any = document.querySelector("link[rel~='icon']");
          if (!link) {
            link = document.createElement("link");
            link.rel = "icon";
            document.getElementsByTagName("head")[0].appendChild(link);
          }
          link.href = resp?.profileImageUrl;

          setIsLoading(false);
        })
        .catch((error: any) => {
          console.log(error);
          document.title = "un:hurd music artists";
          setIsLoading(false);
          setArtistNotFound(true);
        });
    }
  }, [slug]);

  return (
    <>
      {isLoading && !artistDetails && (
        <div className="centered-loading-main h100p">
          <Loading />
        </div>
      )}

      {!isLoading && artistDetails && (
        <div
          className={`theme-${theme}`}
          style={
            {
              "--background-image": `${
                artistDetails?.theme.background.hexCode ||
                "url(" + artistDetails?.theme.background.imageUrl + ")"
              }`,
            } as CSSProperties
          }
        >
          <SEO
            title={artistDetails?.artistName}
            description={artistDetails?.bio}
            name={artistDetails?.artistName}
            image={artistDetails?.profileImageUrl}
          />
          <div className={`main-page`}>
            <div className="pt2rem pb2rem">
              <div className="pl2rem pr2rem">
                <TitleCard name={artistDetails?.artistName} />
              </div>
              <Carousel artistDetails={artistDetails} theme={theme} />
              {artistDetails &&
                !breakPointHit &&
                artistDetails.platformLinks && (
                  <SocialsBar
                    links={artistDetails?.platformLinks}
                    theme={theme}
                  />
                )}
            </div>
          </div>
          {showCookiePolicy && (
            <CookiePopup
              choiceOutput={(output: any) => {
                console.log(output);
                setShowCookiePolicy(false);
                setCookiePolicyAccepted(output);
              }}
            />
          )}
        </div>
      )}
      {!isLoading && artistNotFound && (
        <div className="waves-background">
          <img
            className="mt62 w60p max-w500"
            src="/images/logos/full-text-logo.svg"
            alt=""
          />
          <h3 className="mt120">
            We couldn’t find the artist you’re looking for
          </h3>
          <Button
            className="regular-button btn-white mt20"
            onClick={() => {
              navigate("/");
            }}
          >
            Discover new music
          </Button>
          <div className="text-faded footer">
            <p>
              Are you an artist looking to promote yourself and build a
              community online?{" "}
              <span className="text-white underline cursor-pointer">
                Learn more
              </span>
            </p>
          </div>
        </div>
      )}
    </>
  );
}
